.contents {
    max-width: 850px;
    width: 90%;
    margin: 0px auto;

    color: var(--string-color-base-normal);
}

.desktop {
    font-size: var(--font-size-medium);
}

.smartphone {
    font-size: var(--font-size-large);
}