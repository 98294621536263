.footer {
  background-color: var(--page-color-main);
  text-align: center;

  color: var(--string-color-main-normal);
}

.footerNav {
  width: 80%;
  margin: 0px auto;
}

.navMenu {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 5px;
}

.navMenuLine {
  justify-content: center;
}

.navMenuVertically {
  flex-direction: column;
  align-items: center;
}

.navMenuItemLine {
  margin: 0px 15px;
}

.navMenuItemVertically {
  margin: 3px 0px;
}

.navLink {
  color: var(--string-color-main-normal);
  text-decoration: none;
  font-weight: bold;
}

.navLink:hover {
  /* font-weight: bold; */
  color: var(--string-color-hover-link);
}

.copyright {
  bottom: 10px;
}