.articleContact {
    margin: 0px auto;
    text-align: center;

    font-family: adobe-garamond-pro,HelveticaNeue-Light,"Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
}

.profile {
}

.profileHello {
}

.whatICanDo {
}

.sectionContents {
    width: 400px;
    margin: 0px auto;
}

.formArea {
    width: 400px;
    margin: 0px auto;
    border: none;
}

.twitterIcon {
    margin-right: 10px;
    /* width: 20px;
    height: 20px; */
    width: 1em;
    height: 1em;
}