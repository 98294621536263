.formGroup {
    margin: 10px 0px;
}

.required {
    color: var(--string-color-moderate);
    font-size: 85%;
    margin-left: 5px;
}

.formControll {
    display: block;
    width: 100%;

    border: 2px solid #aaa;
    border-radius: 4px;
    margin: 8px 0;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    transition: 0.3s;
}

.formControll:focus {
    border-color: dodgerBlue;
    box-shadow: 0 0 8px 0 dodgerBlue;
}

.formLabel {
}

.formTextArea {
    width: 100%;
    height: 100px;
}

.annotation {
    
}

.verticalAlign {
    vertical-align: middle;
}