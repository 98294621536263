.schedule {
    width: 100%;
    height: 100%;
    /* height: calc(100vh - var(--header-height) - var(--footer-height)); */
    border-width: 0px;
    margin: 0px;
}

.scheduleWrapper {
    height: 100%;
}