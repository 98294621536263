:root {
    --line-height: 1em;
    --margin-top-bottom: 2.5vh;
}

.calendarDescription {
    margin: 10px 0px;
    font-size: 0.7em;
    line-height: var(--line-height);
}

.calendarComponent {
    height: calc(100vh - (var(--margin-top-bottom) * 2) - var(--header-height) - var(--footer-height));
    margin: var(--margin-top-bottom) 0px;
}