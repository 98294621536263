:root {
  --header-height: 50px;
  /* --footer-height: 75px; */
  --footer-height: 25px;
  --advertisement-width: 150px;
}

.wrapper {
  display: grid;
  grid-template-rows: var(--header-height) 1fr var(--footer-height);
  grid-template-columns: 1fr var(--advertisements-width);
}

.header {
  grid-row: 1 / 2;
  grid-column: 1 / 3;
}

.contentsAds {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.contentsNoAds {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}

.advertisement {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.footer {
  grid-row: 3 / 4;
  grid-column: 1 / 3;
}