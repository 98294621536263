:root {
  /* --header-height is defined layout.module.css */
  --logo-width: var(--header-height);
  --logo-height: var(--header-height); 
  --nav-height: var(--header-height);
  --nav-link-line-height: calc(var(--header-height) * 0.4);
  --nav-link-font-size: calc(var(--nav-link-line-height) * 0.9);
}

.header {
  display: grid;
  /* grid-template-rows: 1fr; */
  grid-template-columns: var(--logo-width) 1fr;

  position: sticky;
  top: 0px;
  background-color: var(--page-color-main);

  color: var(--string-color-main-normal);
}

.headerLogo {
  grid-row: 1;
  grid-column: 1 / 2;

  width: var(--logo-width);
  height: var(--logo-height);

  display: inline-block;
}

.logoImage {
  width: var(--logo-width);
  height: var(--logo-height);
  left: 500px;
}

.headerNav {
  grid-row: 1;
  grid-column: 2 / 3;
}



.navMenu {
  display: flex;

  align-items: center;
  justify-content: flex-end;

  list-style: none;

  margin: 0px;
  padding: 0px;
  height: var(--nav-height);
}

.navMenuItem {
  height: var(--nav-link-line-height);
  padding: calc((var(--nav-height) - var(--nav-link-line-height)) / 2) 15px;
}

.navLink {
  height: var(---nav-link-line-height);
  text-decoration: none;
  font-size: var(--nav-link-font-size);
  font-weight: bold;
  line-height: var(--nav-link-line-height);
  color: var(--string-color-main-normal);
}

.navLink:hover {
  color: var(--string-color-hover-link);
}



.navMenuSubWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navSubMenuOpenButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  outline: none;
}

.navSubMenu {
  top: var(--header-height);
  list-style: none;

  height: var(--nav-height);
  
  vertical-align: middle;
  display: none;
  margin: 0px auto;
  padding: 0px;
  position: absolute;
}

.navSubMenuItem {
  height: var(--nav-link-line-height);
  padding: calc((var(--nav-height) - var(--nav-link-line-height)) / 2) 15px;
  text-align: center;

  background-color: var(--page-color-main);
  border-top: solid 1px var(--string-color-main-normal);
}

.navSubLink {
  height: var(---nav-link-line-height);
  text-decoration: none;
  font-size: var(--nav-link-font-size);
  font-weight: bold;
  line-height: var(--nav-link-line-height);
  color: var(--string-color-main-normal);
}

.navSubLink:hover {
  color: var(--string-color-hover-link);
}

.openNavSubMenu {
  display: block;
}

.underArrow {
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
}