:root {
  --menu-height: calc(var(--nav-height) * 0.7);
  --menu-width: var(--menu-height);
  --menu-border-width: 5px;

  --menu-margin: calc((var(--nav-height) - var(--menu-height)) / 2);
  --middle-point: calc((var(--menu-height) / 2) - (var(--menu-border-width) / 2));
}

.menuTrigger,
.menuTrigger span {
  z-index:2;
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  background-color: var(--page-color-main);
  border: none;
}

.menuTrigger {
  position: relative;
  width: var(--menu-width);
  height: var(--menu-height);
  margin: var(--menu-margin) calc(var(--menu-width) * 0.5) var(--menu-margin) calc(var(--menu-width) * 0.1);
  padding: 0px;
  float: right;
  outline: none;
}

.menuTrigger:hover {
  /* background-color: white; */
}

.menuTrigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--menu-border-width);
  background-color: var(--string-color-main-normal);;
  border-radius: calc(var(--menu-width) * 0.1);
}
.menuTrigger span:nth-of-type(1) {
  top: 0;
}
.menuTrigger span:nth-of-type(2) {
  top: var(--middle-point);
}
.menuTrigger span:nth-of-type(3) {
  bottom: 0;
}

.menuTriggerActive {
  background-color: rgba(0,0,0,0.75);
}
.menuTriggerActive span:nth-of-type(1) {
/* .menuTrigger:active span:nth-of-type(1) { */
  -webkit-transform: translateY(var(--middle-point)) rotate(-45deg);
  transform: translateY(var(--middle-point)) rotate(-45deg);
}
.menuTriggerActive span:nth-of-type(2) {
/* .menuTrigger:active span:nth-of-type(2) { */
  opacity: 0;
}
.menuTriggerActive span:nth-of-type(3) {
/* .menuTrigger:active span:nth-of-type(3) { */
  -webkit-transform: translateY(calc(var(--middle-point) * -1)) rotate(45deg);
  transform: translateY(calc(var(--middle-point) * -1)) rotate(45deg);
}


.modal {
  z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 120vh;
	background-color: rgba(0,0,0,0.75);
}

.inactive {
  display: none;
}

.active {
	display: block;
}





.navMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  list-style: none;

  margin: 10vh 0;
  padding: 0px;
}

.navMenuItem {
  padding: 3vh 0px;
}

.navLink {
  text-decoration: none;
  font-size: calc(var(--menu-height) * 0.5);
  font-weight: bold;
  color: var(--string-color-main-normal);
}

.navLink:hover {
  color: var(--string-color-hover-link);
}



.navMenuSubWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.navSubMenu {
  list-style: none;
  padding: 0px;
}

.navSubMenuItem {
  padding: 2vh 0px;
  text-align: center;
}

.navSubLink {
  text-decoration: none;
  font-size: calc(var(--menu-height) * 0.5);
  font-weight: bold;
  color: var(--string-color-main-normal);
}