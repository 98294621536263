.about {
    margin: 0px 2vw 10vh 2vw;
    text-align: center;
}

.profile {
    margin: 0px 0px 10vh 0px;
}

.whatICanDo {
    margin: 0px 0px 10vh 0px;
}

.sectionContents {
    margin: 10px auto;
    text-align: left;
}

.iCanHelp {
    margin: 0px 0px 10vh 0px;
}