:root {
  /* base color */
  --page-color-base: white;
  --string-color-base-normal: steelblue;
  
  /* main color */
  --page-color-main: steelblue;
  --string-color-main-normal: white;

  /* accent color */
  --page-color-accent: coral;

  /* link hover color */
  --string-color-hover-link: var(--page-color-accent);

  /* font-size */
  --font-size-x-large: 5vw;
  --font-size-large: 4vw;
  --font-size-medium: 3vw;
  --font-size-small: 2vw;
  --font-size-x-small: 1vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* add */
  background-color: var(--page-color-base);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
